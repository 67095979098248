<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal_custom" role="document">
      <div class="modal-content container">
        <div class="modal-body px-4 py-4">
          <p class="titulo_dash">Redefinir senha:</p>
          <ValidationObserver ref="form" class="row" tag="form" @submit.prevent="newSenha()">
            <ValidationProvider
              tag="div"
              class="col-md-5 mb-3 margin_botton_label"
              v-slot="{ errors, ariaMsg, classes }"
              rules="required|min:5|confirmed:confirm"
              name="Senha"
            >
              <label class="mb-1 label_default" :class="classes">Senha</label>
              <input
                id="password"
                type="password"
                class="input_default form-control"
                v-model="password"
                :class="classes"
              />
              <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="col-md-5 mb-3 margin_botton_label"
              v-slot="{ errors, ariaMsg, classes ,validate }"
              rules="required"
              vid="confirm"
              @blur.native="validate($event)"
              name="Confirmação senha"
            >
              <label class="mb-1 label_default">Confirme a senha</label>
              <input id="confirmPassword" type="password" class="input_default form-control" v-model="confirm" />
            </ValidationProvider>
            <div class="col-md-1 mb-3 div-eye margin_botton_label">
              <i
                id="eye"
                :class="showPswd == true ? 'fas fa-eye eye_color' : 'fas fa-eye-slash'"
                @click.prevent="showPassword()"
              ></i>
              <i
                id="lock"
                :class="generatePswd == true ? 'fas fa-lock lock_color' : 'fas fa-lock'"
                @click.prevent="generatePassword()"
              ></i>
            </div>
            <div class="col-lg-12 mb-3">
              <label class="mb-1 label_password">A senha deve conter uma combinação de: letras maiúsculas, minúsculas, números e símbolos (!#@$%&).</label>
            </div>
            <div class="col-md-3 ml-auto">
              <button
                class="btn_default d-flex justify-content-center align-items-center"
                :disabled="disabled"
                type="submit"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="disabled"
                ></span>
                <i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
                {{ !disabled ?`Salvar` : "" }}
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["editUser"],
  data() {
    return {
      confirm: "",
      password: "",
      disabled: false,
      showPswd: false,
      generatePswd: false
    };
  },
  mounted() {
    var self = this;
    $("#exampleModal").on("hide.bs.modal", function(e) {
      self.$parent.editUser = [];
      self.$parent.cp = "";
    });
  },
  methods: {
    newSenha() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          HTTP.post(
            `services/app/User/ChangePasswordFromFranchiseOrMaster`,
            {
              password: this.password,
              userId: this.editUser.id
            },

            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getterToken}`
              }
            }
          )
            .then(response => {
              this.disabled = false;
              this.$notify({
                group: "erros",
                type: "sucess",
                text: `<i class="icon ion-close-circled"></i>Senha alterada`
              });
              $("#exampleModal").modal("hide");
            })
            .catch(error => {
              console.log(error);
              this.disabledDelete = false;
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
              });
            });
        }
      });
    },
    showPassword() {
      if (this.showPswd == true) {
        this.showPswd = false;
        $("#password").attr("type", "password");
        $("#confirmPassword").attr("type", "password");
      } else {
        this.showPswd = true;
        $("#password").attr("type", "text");
        $("#confirmPassword").attr("type", "text");
      }
    },
    generatePassword() {
      if (this.generatePswd == true) {
        this.generatePswd = false;
        $("#password").attr("value", "");
        $("#confirmPassword").attr("value", "");
        $("#password")[0].value = "";
        $("#confirmPassword")[0].value = "";
      } else {
        this.generatePswd = true;
        let stringAleatoria = "";
        let caracteresMaiusculos = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let caracteresMainusculos = 'abcdefghijklmnopqrstuvwxyz';
        let numeros = '0123456789';
        let caracteresEspeciais = "!#@$%&"
        for (let i = 0; i < 2; i++) {
          stringAleatoria += caracteresMaiusculos.charAt(Math.floor(Math.random() * caracteresMaiusculos.length))
            + caracteresMainusculos.charAt(Math.floor(Math.random() * caracteresMainusculos.length))
            + numeros.charAt(Math.floor(Math.random() * numeros.length))
            + caracteresEspeciais.charAt(Math.floor(Math.random() * caracteresEspeciais.length));
        }
        let stringArray = stringAleatoria.split();
        for (let i = stringArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [stringArray[i], stringArray[j]] = [stringArray[j], stringArray[i]];
        }
        stringAleatoria = stringArray.join();
        this.password = stringAleatoria;
        this.confirm = stringAleatoria;
      }
    }
  }
};
</script>

<style scoped>
.failed input {
  border: 2px solid #dc3545 !important;
}
.failed span {
  font-size: 10px !important;
}

.fas {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}

.modal_custom {
  max-width: 640px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}

label.failed {
  color: #dc3545 !important;
}

.input_default {
  height: 45px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.div-eye {
    display: flex;
    align-items: center;
}
#eye {
  margin-top: 10px;
  margin-right: 10px;
  color: #9C9C9C;
}
#lock {
  margin-top: 10px;
  color: #9C9C9C;
}
.label_password{
  color: #9C9C9C;
  font-size: 10px;
}
.margin_botton_label{
  margin-bottom: 1px !important;
}
.eye_color, .lock_color{
  color: #e0a638 !important;
}
</style>