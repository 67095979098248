
<template>
	<div class="row">
		<div class="col-md-12">
			<div class="row align-items-center">
				<div class="col-md-4" id="userContAdm">
					<p class="mb-0 titulo_dash">Usuários</p>
				</div>

				<div class="col-md-3 ml-auto">
					<button
						class="btn_default d-flex justify-content-center align-items-center"
						@click.prevent="newPeople()"
					>
						<i class="fas fa-plus-circle mr-1"></i>Add Usuário
					</button>
				</div>
			</div>
		</div>
		<div class="col-md-12 my-4">
			<div class="box" :style="load ? 'height:250px;':'min-height:100%;'">
				<loader color="#e0a638" size="100" v-if="load" />
				<div class="box_content w-100 h-100">
					<p
						class="my-5 text-center w-100"
						style="font-size:16px;"
						v-if="msg !=''"
					>Ainda não existem pessoas cadastradas</p>
					<transition-group name="list-complete" tag="div">
						<div
							class="box_item list-complete-item"
							v-for="(item ,index) in pessoas"
							:key="index + 1"
							@mouseenter="enterItem(index)"
							@mouseleave="exitItem(index)"
						>
							<div class="d-flex align-items-center h-100" id="infoCont">
								<div class="col-md-3 mx-auto">
									<div class="row align-items-center">
										<div class="col-md-4">
											<div class="div_image mx-auto" v-if="!load && pessoas">
												<img
													:src="getImg(item.photo)"
													class="img_user"
													@error="!item.photo ? errorImage($event) : errorImage($event)"
												/>
											</div>
										</div>
										<div class="col-md-8 px-0 text-left" id="userNAME">
											<p class="mb-0">{{item.displayName}}</p>
										</div>
									</div>
								</div>
								<div class="col-md-2 text-center mx-auto px-1" id="userCPF">
									<p class="mb-0">{{cpfMask(item.cpf)}}</p>
								</div>
								<div class="col-md-2 text-center mx-auto" id="userPROFILE">
									<p class="mb-0 px-3">{{getProfile(item.profile)}}</p>
								</div>
								<div class="hideButtonsCont">
									<div class="col-md-3 text-center mx-auto">
										<a class="opacity" :class="`opacity_${index}`" @click.prevent="redefinirSenha(item)">
											Redefinir senha
											<i class="ml-2 fas fa-pen"></i>
										</a>
									</div>

									<div class="col-md-2 mx-auto">
										<a class="opacity" :class="`opacity_${index}`" @click.prevent="editItem(item)">
											Editar
											<i class="ml-2 fas fa-pen"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</transition-group>
				</div>
			</div>
			<component :is="cp" @getPessoas="getPessoas" :editUser="editUser" />
		</div>
		<modalDegustacao/>
	</div>
</template>

<script>
	import modal from "./modal/addPessoa";
	import loader from "@/components/utils/_Load";
	import modalSenha from "./modal/modalSenha";
	import modalDegustacao from "@/components/modal/modalDegustacao";
	export default {
		name: "signup",
		components: {
			modal,
			loader,
			modalSenha,
			modalDegustacao
		},
		data() {
			return {
				pessoas: [],
				load: true,
				editUser: [],
				cp: "",
				msg: ""
			};
		},
		mounted() {
			this.getPessoas();
		},
		watch: {},
		methods: {
			getPessoas() {
				this.pessoas = "";
				this.load = true;
				this.$nextTick(() => {
					this.$store
						.dispatch("getPessoas")
						.then(resolve => {
							this.$nextTick(() => {
								this.pessoas = resolve;
								this.load = false;
								var array = [];
								this.pessoas.forEach(item => {
									if (item.profile == 2)
										array.push({
											userId: item.id,
											displayName: item.displayName
										});
								});
								this.$parent.listUser = array;
								if (resolve == "") {
									this.msg = "Ainda não existem pessoas cadastradas";
								} else {
									this.msg = "";
								}
							});
						})
						.catch(reject => {
							this.load = false;
							this.msg = "Ops algo deu errado";
							this.$notify({
								group: "erros",
								type: "error",
								text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
							});
						});
				});
			},
			enterItem(index) {
				$(`.opacity_${index}`).fadeIn(300);
			},
			exitItem(index) {
				$(`.opacity_${index}`).fadeOut(200);
			},
			newPeople() {
				this.cp = "modal";
				if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
          $("#modalDegustacao").modal("show");
        } else {
          this.$nextTick(() => {
            $("#modalPessoa").modal("show");
          });
        }
			},
			editItem(item) {
				this.cp = "modal";
        if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
          $("#modalDegustacao").modal("show");
        } else {
          this.editUser = Object.assign({}, item);
          this.$nextTick(() => {
            $("#modalPessoa").modal("show");
          });
        }
			},
			redefinirSenha(item) {
				this.cp = "modalSenha";
        if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
          $("#modalDegustacao").modal("show");
        } else {
          this.editUser = Object.assign({}, item);
          this.$nextTick(() => {
            $("#exampleModal").modal("show");
          });
        }
			}
		},
		computed: {}
	};
</script>

<style scoped>
.box_content {
	overflow: auto;
	border-radius: 18px;
	max-height: 250px;
}
.opacity {
	display: none;
}
.dropdown-item:focus,
.dropdown-item:hover {
	background-color: #2474b2;
}
.dropdown-menu {
	background-color: #175d93;
}
.drop p,
a {
	font-size: 14px;
}
.drop p {
	color: #175d93;
}
.drop a {
	font-weight: 500;
}
.drop {
	width: 100%;
	justify-content: flex-end;
}

p,
a {
	color: #ffffff;
}
a {
	cursor: pointer;
	transition: all 0.2s linear;
}
a:hover {
	color: #ffffff;
	text-decoration: underline;
}
.fa-plus-circle {
	font-size: 20px;
}
.btn_default {
	background-color: #e0a638;
	height: 40px;
	border: none;
}
.btn_default:hover {
	background-color: #f38235;
}
.div_image {
	border-radius: 50%;
	overflow: hidden;
	height: 40px;
	width: 40px;
}
.img_user {
	width: 100%;
	height: 40px;
	object-fit: cover;
}

.opacity {
	display: none;
}

.box_item >>> p {
	font-size: 14px;
}
.box {
	width: 100%;
	border-radius: 20px;
	overflow: hidden;
	border-color: #357eb8;
	background: #357eb8;
	border-style: solid;
	max-height: 250px;
	border-width: 0px 3px 0px 3px;
}
.box_item {
	height: auto;
	padding: 15px 0;
	cursor: pointer;
}
.box_item:nth-child(odd) {
	background-color: #357eb8;
}
.box_item:nth-child(even) {
	background-color: #175d93;
}

p,
a {
	color: #ffffff;
}
a {
	cursor: pointer;
	transition: all 0.2s linear;
}
a:hover {
	color: #ffffff;
	text-decoration: underline;
}
@media screen and (max-width:767px){
	#userContAdm{
		margin-bottom: 2%;
	}
	#userCPF{
		min-width: 120px;
	}
	#userNAME{
		min-width: 88px;
    	text-align: center!important;
	}
	#infoCont{
		flex-wrap: wrap;
	}
	p{
		margin-bottom: 5px!important;
		margin-top: 2px!important;
	}
	#userPROFILE p{
		color: #e0a638;
	}

}
</style>
