<template>
  <div class="adm py-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Administrativo</p>
        </div>
    </div>
    <div class="container">
      <div class="col-md-12">
        <div class="row align-items-baseline">
          <div class="col-md-2 mx-auto">
            <p class="titulo_dash mb-4" v-if="this.sizeWindow > 767">Administrativo</p>
            <div class="menu d-flex flex-wrap">
              <a
                id="itemAdmSelect"
                href="#"
                class="mb-3"
                v-for="(item, index) in listCp"
                :key="index"
                :style="item.cp == cpAdm ? 'font-weight: 700;' : ''"
                @click.prevent="getCp(item.cp)"
                >{{ item.nome }}</a
              >
            </div>
          </div>
          <div class="col-md-9 mx-auto" id="componentCont">
            <component :is="cpAdm" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import categoriaServico from "@/components/adm/categoria_servico";
import suporte from "@/components/adm/categoria_suporte";
import unidadeUser from "@/components/adm/unidades_usuario";
import terminalTef from "@/components/adm/terminais_tef.vue";
export default {
  components: {
    unidadeUser,
    categoriaServico,
    suporte,
    terminalTef
  },
  data() {
    return {
      cpAdm: "unidadeUser",
      listCp: [
        { nome: "Usuários e Unidades", cp: "unidadeUser" },
        { nome: "Categorias e Serviços", cp: "categoriaServico" },
        { nome: "Terminais Tef", cp: "terminalTef" },
        { nome: "Suporte", cp: "suporte" }
      ],
      sizeWindow: 0,
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  methods: {
    getCp(val) {
      this.cpAdm = val;
    }
  }
};
</script>

<style scoped>
.adm {
  background-color: #2474b2;
}
.menu a {
  color: #ffffff;
  width: 100%;
}
@media screen and (max-width:767px){
  
.welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont{
    width: 44%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 28%;
  }
  .adm{
    padding-top: 0!important;
    padding-bottom: 3%!important;
  }
  #itemAdmSelect{
    border-bottom: 1px rgb(190, 190, 190) solid;
    padding-bottom: 5px;
    font-size: 14px;
    text-decoration: none;
  }
  #componentCont{
    padding: 0!important;
  }
}
</style>
