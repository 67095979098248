<template>
  <div
    class="tab-pane fade col-md-12"
    id="serviceComissions"
    role="tabpanel"
    aria-labelledby="serviceComissions-tab"
  >
    <div class="mb-2">
      <div class="col-md-12 mb-1 form-group form-outline">
        <label class="label_default label_blue">
          Comissão
        </label>
      </div>
      <div class="col-md-12 row">
        <div class="col-md-auto">
          <div tag="div" class="d-flex align-items-center form-check">
            <div class="mr-2 field-checkbox">
              <input
                class="field"
                type="checkbox"
                id="noComission"
                :checked="!hasComission"
                :value="!hasComission"
                @change="
                  $emit(
                    'update:hasComission',
                    $event.target.checked ? false : true
                  )
                "
              />
              <label class="description" for="noComission"></label>
            </div>
            <p
              class="label_default _checkbox mb-2 list"
              @click="getCheckBox($event.target)"
            >
              Inativo
            </p>
          </div>
        </div>
        <div class="col-md-auto">
          <div class="d-flex">
            <div class="mr-2 field-checkbox">
              <input
                class="field"
                type="checkbox"
                id="hasComission"
                :checked="hasComission"
                :value="hasComission"
                @change="
                  $emit(
                    'update:hasComission',
                    $event.target.checked ? true : false
                  )
                "
              />
              <label class="description" for="hasComission"></label>
            </div>
            <p
              class="label_default _checkbox mb-2 list"
              @click="getCheckBox($event.target)"
            >
              Venda à vista
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="hasComission">
      <div class="col-md-7 d-flex">
        <ValidationProvider
          tag="div"
          v-slot="{ errors, ariaMsg, classes }"
          :rules="{
            max: 12
          }"
          class="col-md-6 mb-1 form-group form-outline"
          name="Comissão MASTER"
        >
          <label class="mb-1 label_default" :class="classes">C. MASTER</label>
          <input
            class="form-control input_default"
            :class="classes"
            :value="comissionMaster"
            maxlength="12"
            @input="$emit('update:comissionMaster', $event.target.value)"
            @keypress="checkValueOnly($event)"
            @blur="
              $event.target.value != ''
                ? $emit(
                    'update:comissionMaster',
                    valueConvertBR(parseFloat($event.target.value).toFixed(2))
                  )
                : ''
            "
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          v-slot="{ errors, ariaMsg, classes }"
          :rules="{
            max: 12
          }"
          class="col-md-6 mb-1 form-group form-outline"
          name="Comissão Franqueado"
        >
          <label class="mb-1 label_default" :class="classes"
            >C. Franqueado</label
          >
          <input
            class="form-control input_default"
            :class="classes"
            :value="comissionFranchisee"
            maxlength="12"
            @keypress="checkValueOnly($event)"
            @input="$emit('update:comissionFranchisee', $event.target.value)"
            @blur="
              $event.target.value != ''
                ? $emit(
                    'update:comissionFranchisee',
                    valueConvertBR(parseFloat($event.target.value).toFixed(2))
                  )
                : ''
            "
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4 mt-4">
        <div class="col-md-5">
          <button
            type="button"
            class="btn btn_default"
            @click="openModal('modal', {})"
          >
            + Adicionar
          </button>
        </div>
      </div>
      <listServiceComissions
        :listValues.sync="listValues"
        @openModal="openModal"
      />
      <component
        :is="cpCreate"
        :serviceTenantId="serviceTenantData.id"
        :tenantId.sync="serviceTenantData.tenantId"
        :tenanancyName.sync="serviceTenantData.tenanancyName"
        :comissionSallesman.sync="serviceTenantData.comissionSallesman"
        :comissionFranchisee.sync="serviceTenantData.comissionFranchisee"
        :comissionMaster.sync="serviceTenantData.comissionMaster"
        :tenants.sync="serviceTenantData.tenants"
        :load="serviceTenantData.load"
        @closeModal="closeModal"
        @submitform="submitform"
      />
      <component
        :is="cpDelete"
        :serviceName="serviceName"
        :tenanancyName="serviceTenantData.tenanancyName"
        @closeModal="closeModal"
        @removeTenant="removeTenant"
      />
    </div>
  </div>
</template>

<script>
import modal from "./components/valuesAndComissions/modal/createEditComissions.vue";
import modalDelete from "./components/valuesAndComissions/modal/delete.vue";

export default {
  props: [
    "serviceName",
    "listValues",
    "hasComission",
    "comissionFranchisee",
    "comissionMaster",
    "comissionSallesman"
  ],
  components: {
    listServiceComissions: () =>
      import("./components/valuesAndComissions/tableComissionTenants.vue"),
    modal,
    modalDelete
  },
  data() {
    return {
      serviceTenantData: {
        id: "",
        tenantId: "",
        tenanancyName: "",
        tenants: [],
        load: false,
        comissionSallesman: "",
        comissionMaster: "",
        comissionFranchisee: ""
      },
      cpDelete: "",
      cpCreate: ""
    };
  },
  methods: {
    openModal(
      idModal,
      {
        id,
        tenantId,
        tenanancyName,
        tenants,
        comissionSallesman,
        comissionMaster,
        comissionFranchisee
      }
    ) {
      this.serviceTenantData = {
        id,
        tenantId,
        tenanancyName,
        tenants,
        comissionSallesman,
        comissionMaster,
        comissionFranchisee
      };
      if (idModal === "modal") {
        this.cpCreate = idModal;
        const news = [undefined, ""];
        const query = news.some(
          s =>
            s === this.serviceTenantData.tenanancyName ||
            s === this.serviceTenantData.tenantId
        );
        if (query) {
          this.getTenantsActive();
          this.serviceTenantData.tenantId = "";
          this.serviceTenantData.tenanancyName = "";
          this.serviceTenantData.id = "";
        }
      } else if (idModal === "modalDelete") {
        this.cpDelete = idModal;
      } else {
        this.$toast(
          `Não foi possivel identificar a operação`,
          this.$toast.error
        );
        return;
      }
    },
    closeModal(idModal) {
      $(`#${idModal}`).modal("hide");
      this.cpDelete = "";
      this.cpCreate = "";
    },
    getTenantsActive() {
      this.$store
        .dispatch("getAllTenants")
        .then(resolve => {
          this.serviceTenantData.load = false;
          const result = resolve.activeTenants;

          this.serviceTenantData.tenants = result.filter(
            r => !this.listValues.some(lv => lv.tenantId == r.id)
          );
        })
        .catch(reject => {
          console.log("error", reject);
        });
    },
    submitform() {
      this.$emit("updateListValues", this.serviceTenantData);
    },
    removeTenant(tenantName) {
      this.$emit("removeListValues", tenantName);
    },
    getCheckBox(event) {
      this.$emit("getCheckBox", event);
    }
  },
  watch: {
    cpCreate: function() {
      if (this.cpCreate != "") {
        setTimeout(() => {
          $(`#${this.cpCreate}`).modal("show");
        }, 100);
      }
    },
    cpDelete: function() {
      if (this.cpDelete != "") {
        setTimeout(() => {
          $(`#${this.cpDelete}`).modal("show");
        }, 100);
      }
    }
  }
};
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.label_default {
  overflow: hidden !important;
  text-overflow: ellipsis;
}
@media (max-width: 402px) {
  .d-flex {
    display: inline !important;
  }
}
</style>
