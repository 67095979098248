<template>
   <div class="unidades">
      <pessoas ref="pessoas" />
      <unidades
         ref="unidades"
         :listUser="listUser"
         v-if="$store.getters.getUser.profile == 1"
      />
      <div class="container">
         <div class="row">
            <div class="col-md-3 ml-auto px-0" v-if="$store.getters.getUser.profile == 2 && $store.getters.tokenOriginal != ''">
               <button class="btn_default d-flex justify-content-center align-items-center" @click.prevent="returnMaster()">
                  <i class="fas fa-sign-in-alt mr-1" style="font-size: 20px;"></i>
                  Retornar para master
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import pessoas from "./unidades/pessoa_unidade";
import unidades from "./unidades/add_unidade";
export default {
  components: {
    pessoas,
    unidades
  },
  data() {
    return {
      listUser: "",
      showCp: false
    };
  },
  mounted() {},
  methods: {
    returnMaster() {
      this.$store.commit("set_user", this.$store.getters.originalLogin);
      this.$store.commit("set_token", this.$store.getters.tokenOriginal);
      this.$store.commit("set_tenant", this.$store.getters.originalTenant);
      this.$store.commit("set_userScore", 0);
      this.$store.commit("set_originalToken", "");
      this.$store.commit("set_loginClone", "");
      this.$store.commit("set_tenantClone", "");
      this.$store.dispatch("getAlert");
      this.$store.dispatch("getAlertImportant");
      this.toast("Unidade Master!", this.$toast);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}

.btn_default:hover {
  background-color: #f38235;
}

.btn-editcolor {
  background-color: #114a75 !important;
}
</style>
