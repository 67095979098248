<template>
  <div
    class="tab-pane fade col-md-12"
    id="sallesRelational"
    role="tabpanel"
    aria-labelledby="sallesRelational-tab`"
  >
    <div class="row">
      <div class="col-md-9 d-flex">
        <div class="col-md-12 mb-1 form-group form-outline">
          <label class="mb-1 label_default">Buscar Serviço</label>
          <input v-model="service" class="input_default form-control" />
        </div>
      </div>
      <div class="col-md-2 mt-4">
        <div class="col-md-2">
          <button type="button" class="btn btn_default">
            + Adicionar
          </button>
        </div>
      </div>
    </div>
    <!-- <serviceRelationalSalles :serviceRelationals.sync="serviceRelationals" /> -->
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import { TheMask } from "vue-the-mask";
import { mask } from "vue-the-mask";

export default {
  props: ["serviceRelationals"],
  directives: { mask },
  components: {
    HalfCircleSpinner,
    TheMask
    // serviceRelationalSalles: () =>
    //   import("./components/serviceComissions/serviceRelationalSalles.vue")
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.label_default {
  overflow: hidden !important;
  text-overflow: ellipsis;
}
@media (max-width: 402px) {
  .d-flex {
    display: inline !important;
  }
}
</style>
