<template>
  <div class="row m-auto col-md-12">
    <div class="col-md-3 mr-auto">
      <button
        v-if="id === '' && tabs.findIndex(t => t.show == true) > 0"
        type="button"
        @click.prevent="
          getTab(tabs[indexSelect == 0 ? 0 : indexSelect - 1].tabName)
        "
        class="btn btn_transparent"
      >
        <img src="@/assets/image/icones/arrowBack.svg" alt="Voltar" />Voltar
      </button>

      <button
      v-if="id !== ''"
        type="button"
        class="btn btn_delete"
        :disabled="disabled"
        @click.prevent="$emit('openModalDeleteService')"
      >
        Excluir Serviço
      </button>
    </div>
    <div class="col-md-8 ml-auto">
      <div class="row">
        <div
          class="col-md-5 m-auto"
          v-show="
            tabs.findIndex(t => t.show == true) == tabs.length || id != ''
          "
        >
          <button
            v-if="getTabShowButton(tabs[indexTab].tabName)"
            type="button"
            class="btn btn_delete"
            @click.prevent="$emit('openModalDeleteUnidades')"
          >
            Excluir Selecionados
          </button>
        </div>
        <div
          class="col-md-3 m-auto"
          v-show="
            tabs.findIndex(t => t.show == true) == tabs.length || id != ''
          "
        >
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModal')"
          >
            Cancelar
          </button>
        </div>
        <div
          class="col-md-4 m-auto"
          v-if="
            tabs.findIndex(t => t.show == true) + 1 == tabs.length || id != ''
          "
        >
          <button
            type="button"
            :disabled="disabled"
            @click.prevent="$emit('sendService')"
            class="btn btn_default"
          >
            {{ id != "" ? "Salvar" : "Criar" }}
          </button>
        </div>
        <div class="col-md-4 m-auto" v-else>
          <button
            class="btn btn_secondary"
            @click.prevent="
              getTab(
                tabs[
                  tabs.findIndex(t => t.show) == tabs.length - 1
                    ? tabs.findIndex(t => t.show)
                    : tabs.findIndex(t => t.show) + 1
                ].tabName
              )
            "
            v-if="tabs.findIndex(t => t.show == true) >= 0 && id == ''"
          >
            Proximo
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Create -->
</template>

<script>
export default {
  props: ["id", "tabs", "disabled"],
  data() {
    return {
      indexSelect: 0,
      indexTab: 0,
      load: false
    };
  },
  methods: {
    showTabCreate(index) {
      this.indexTab = index;
      let newArray = this.tabs;
      let oldIndex = newArray.findIndex(t => t.show);

      newArray[oldIndex].show = false;
      newArray[index].show = true;
    },
    getTab(tabName) {
      console.log(tabName);
      document.getElementById(`${tabName}-tab`).click();
    },
    getTabShowButton(tabName) {
      console.log(tabName);
      return tabName == "serviceValues" ? true : false;
    },
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
