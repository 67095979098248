<template>
	<div>
		<div class="modal fade" id="modalSuporte" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable modal_custom" role="document">
				<div class="modal-content">
					<div class="modal-body px-4 py-4" :style="load ? 'height: 298px' : ''">
						<loader color="#e0a638" size="100" v-if="load" />
						<div v-else>
							<ValidationObserver ref="form" class="col-md-12" tag="form" @submit.prevent="sendService()">
								<topo :type.sync="superObj.type" :link.sync=" superObj.link" :nameModal.sync="nameModal" />
								<service 
									:name.sync=" superObj.name"
								 	:supplier.sync="superObj.supplier"
								 	:clerk.sync="superObj.clerk"
									:phone.sync="superObj.phone" 
									:secondaryPhone.sync="superObj.secondaryPhone"
									:email.sync="superObj.email"
								/>
								<transition-group name="list-complete" tag="div">
									<addNew :instructions.sync=" superObj.instructions" class="list-complete-item" key="01" />
									<listInstrucao
										v-for="item in superObj.instructions"
										:key="item.sequence"
										:item="item"
										class="list-complete-item"
										:instructions.sync="superObj.instructions"
									/>
								</transition-group>
								<popUp :hasPopup.sync="superObj.hasPopup" />
								<div class="row mt-4">
									<div class="col-md-3 px-0" v-if="idService">
										<button
											class="btn_default d-flex justify-content-center align-items-center btn_default_excluir"
											:disabled="disabledDelete"
											:class="disabled ? 'btn_default_disabled' : ''"
											@click.prevent="deleteService()"
										>
											<span
												class="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
												v-show="disabledDelete"
											></span>
											<i class="fas fa-trash-alt mr-1" v-show="!disabledDelete"></i>
											{{ !disabledDelete ?`Excluir` : "" }}
										</button>
									</div>
									<div class="col-md-3 ml-auto px-0">
										<button class="btn_default d-flex justify-content-center align-items-center">
											<span
												class="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
												v-show="disabled"
											></span>
											<i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
											{{ !disabled ?`${idService ?'Editar': 'Criar'}` : "" }}
										</button>
									</div>
								</div>
							</ValidationObserver>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import loader from "@/components/utils/_Load";
	import HTTP from "@/api_system";
	import topo from "./service_cp/topo";
	import service from "./service_cp/nameService";
	import addNew from "./service_cp/addInstruct";
	import linkInput from "./service_cp/linkVideo";
	import popUp from "./service_cp/popUp";
	import listInstrucao from "./service_cp/listInstrucao";
	export default {
		components: {
			topo,
			service,
			addNew,
			linkInput,
			listInstrucao,
			loader,
			popUp,
		},
		props: ["category", "idService"],
		data() {
			return {
				load: false,
				disabled: false,
				disabledDelete: false,
				nameModal: "Adicionar Suporte",
				superObj: {
					name: "",
					suplier: "",
					clerk: "",
					phone: "",
					secondaryPhone: "",
					email: "",
					link: "",	
					hasPopup: false,
					category: { id: this.category.id, name: this.category.name },
					instructions: [],
				},
			};
		},
		mounted() {
			var self = this;
			$("#modalSuporte").on("hide.bs.modal", function (e) {
				self.$parent.cpModal = "";
				self.$parent.idService = "";
			});
		},
		methods: {
			sendService() {
				this.$refs.form.validate().then((success) => {
					if (success) {
						this.disabled = true;
						var url;
						var metodo;

						if (this.idService) {
							url = "services/app/Suport/UpdateSuport";
							metodo = HTTP.put;
						} else {
							url = "services/app/Suport/CreateSuport";
							metodo = HTTP.post;
						}
						metodo(url, this.superObj, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getterToken}`,
							},
						})
							.then((response) => {
								this.$notify({
									group: "erros",
									type: "sucess",
									text: `<i class="icon ion-close-circled"></i>${
										this.idService
											? "Suporte editado com sucesso!"
											: "Suporte criado com sucesso!"
									}`,
								});

								setTimeout(function () {
									$("#modalSuporte").modal("hide");
								}, 2500);
								this.$store.dispatch("getSuporte");
								this.$nextTick(() => {
									$("#modalCategoria").modal("hide");
									this.disabled = false;
								});
							})
							.catch((error) => {
								this.disabled = false;
								this.$notify({
									group: "erros",
									type: "error",
									text: `<i class="icon ion-close-circled"></i>${error.response.data.error.message}`,
								});
							});
					}
				});
			},
			getEditService() {
				this.load = true;
				HTTP.get(
					`services/app/Suport/GetSuportForEdit?id=${this.idService}`,

					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getterToken}`,
						},
					}
				).then((response) => {
					this.load = false;
					this.superObj = [];
					this.superObj = response.data.result;
				});
			},
			deleteService() {
				this.disabledDelete = true;
				HTTP.delete(
					`services/app/Suport/Delete?id=${this.idService}`,

					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getterToken}`,
						},
					}
				).then((response) => {
					this.$notify({
						group: "erros",
						type: "error",
						text: `<i class="icon ion-close-circled"></i>Suporte deletado com sucesso!`,
					});
					this.disabledDelete = false;
					this.$store.dispatch("getSuporte");
					var connection = new signalR.HubConnectionBuilder()
						.configureLogging(signalR.LogLevel.Debug)
						.withUrl(`${process.env.VUE_APP_ROOT_API}/signalr`, {
							skipNegotiation: true,
							transport: signalR.HttpTransportType.WebSockets,
						})

						.build();

					connection
						.start()
						.then(function () {
							connection.invoke("send", "type", 3);
						})
						.catch((error) => {});
					this.$nextTick(() => {
						setTimeout(function () {
							$("#modalSuporte").modal("hide");
						}, 2500);
					});
				});
			},
		},
		watch: {
			idService: function () {
				if (this.idService) {
					(this.nameModal = "Editar Suporte"), this.getEditService();
				} else {
					this.nameModal = "Adicionar Suporte";
				}
			},
		},
		computed: {},
	};
</script>

<style scoped>
.input_flex {
	width: 65px;
}

.fas {
	font-size: 20px;
}
.btn_default {
	background-color: #e0a638;
	height: 40px;
	border: none;
}
.btn_default:hover {
	background-color: #f38235;
}
.btn_default_excluir {
	background-color: #e67979;
	height: 40px;
	border: none;
}
.btn_default_excluir:hover {
	background-color: #dc3545;
}
.btn_default_disabled {
	background-color: #e3e3e3;
}
.modal_custom {
	max-width: 590px;
}

.modal-content {
	border-radius: 20px;
}

label.failed {
	color: #dc3545 !important;
}
::-webkit-scrollbar-track {
	background-color: #2474b2;
}

::-webkit-scrollbar {
	width: 5px;
	background: #2474b2;
}

::-webkit-scrollbar-thumb {
	background: #e0a638;
	border-radius: 10px;
}
</style>
