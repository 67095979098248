<template>
  <div class="row" v-show="!load" id="unidades">
    <div class="col-md-12">
      <div class="row align-items-center">
        <div class="col-md-4">
          <p class="mb-0 titulo_dash">Unidades</p>
        </div>

        <div class="col-md-3 ml-auto" id="userContAdm">
          <button
            class="btn_default d-flex justify-content-center align-items-center"
            @click.prevent="showModal()"
          >
            <i class="fas fa-plus-circle mr-1" v-show="id == ''"></i>
            {{ id == "" ? "Add Unidade" : "Editar Unidade" }}
          </button>
        </div>
        <transition name="vertical">
          <div class="col-md-3 my-3" v-if="id">
            <button
              class="btn_default d-flex justify-content-center align-items-center"
              @click.prevent="enterTenant()"
              :disabled="disabled"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="disabled"
              ></span>
              <i
                class="fas fa-sign-in-alt mr-1"
                style="font-size: 20px;"
                v-show="!disabled"
              ></i>
              {{ !disabled ? "Entrar" : "" }}
            </button>
          </div>
        </transition>
      </div>
    </div>
    <div class="col-md-12 my-4">
      <div class="box py-3">
        <div v-if="unidades == ''" class="w-100">
          <p class="my-5 text-center w-100" style="font-size:16px;">
            Ainda não existem unidades cadastradas
          </p>
        </div>
        <transition-group
          name="list-complete"
          tag="div"
          class="row align-items-center h-100 w-100"
          id="boxContUnity"
          ref="teste"
        >
          <div
            class="col-md-4 list-complete-item"
            v-for="(item, index) in unidades"
            :key="index + 1"
          >
            <div
              class="box_item"
              @click.prevent="editar(item.id, item.userId)"
              :class="item.id == id ? 'active' : ''"
            >
              <div class="sigla mr-2">
                <span>{{ item.reference ? item.reference : "DEF" }}</span>
              </div>
              <p class="mb-0">
                {{ item.tenancyName }}
                <span>({{ selectPort(item.port) }})</span>
              </p>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <component
      :is="cp"
      :listUser="listUser"
      :id.sync="id"
      :tenantDisableds="desativo"
      :tenantsActives="unidades"
      @closeModal="closeModal"
      @getUnidades="getUnidades"
    />
  </div>
</template>

<script>
import modal from "./modal/modalUnidade";
import HTTP from "@/api_system";

export default {
  props: ["listUser"],
  name: "addUnidade",
  components: {
    modal
  },
  data() {
    return {
      load: true,
      unidades: [],
      desativo: [],
      cp: "",
      id: "",
      idUser: "",
      disabled: false
    };
  },
  mounted() {
    this.getUnidades();
  },
  watch: {
    listUser: function() {},
    desativo: function() {}
  },
  methods: {
    selectEstado(item) {
      this.selectEstate = item;
      this.cidadeSelect = this.cidadeEstado[0];
    },
    getUnidades() {
      this.load = true;
      HTTP.post("services/app/Tenant/ListOfTenants", "", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.unidades = response.data.result.activeTenants;
          this.desativo = response.data.result.desactiveTenants;

          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });
    },
    showModal() {
      this.cp = "modal";
      this.$nextTick(() => {
        $("#modalUnidade").modal("show");
      });
    },
    closeModal() {
      $(`#modalUnidade`).modal("hide");
      this.cp = "";
    },
    editar(idUnidade, id) {
      if (this.id == idUnidade) {
        this.id = "";
        this.idUser = "";
      } else {
        this.id = idUnidade;
        this.idUser = id;
      }
    },
    enterTenant() {
      this.disabled = true;
      HTTP.post(
        `TokenAuth/AuthenticateFromMaster?tenantId=${this.id}&userId=${this.idUser}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$store.commit(
            "set_originalToken",
            this.$store.getters.getterToken
          );
          this.$store.commit(
            "set_loginClone",
            this.$store.state.login.userLogin
          );
          this.$store.commit(
            "set_tenantClone",
            this.$store.state.tenent.tenant
          );
          this.$nextTick(() => {
            this.disabled = false;
            this.$store.commit("set_token", response.data.result.accessToken);
            this.$store.commit("set_user", response.data.result.informations);
            this.$store.commit("set_tenant", response.data.result.tenant);

            this.$router.push("/");
            this.$store.dispatch("getAlert");
            this.$store.dispatch("getAlertImportant");
            this.toast(`Unidade Selecionada!`, this.$toast);
          });
        })
        .catch(error => {
          this.toast("Ops algo deu errado", this.$toast.error);
          this.disabled = false;
        });
    }
  },
  computed: {}
};
</script>

<style scoped>
.active .sigla {
  border: 2px solid #ffffff;
}
.active .sigla span {
  color: #ffffff;
}
.sigla {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #175d93;
  border-radius: 50%;
}
.sigla span {
  font-size: 10px;
  color: #175d93;
  font-weight: 500;
}
.active p span {
  color: #e0a638 !important;
}
.active p {
  color: #fff !important;
}

.box_item p {
  color: #175d93;
}
.box_item {
  height: auto;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.box {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #357eb8;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #2474b2;
}
.dropdown-menu {
  background-color: #175d93;
}
.drop p,
a {
  font-size: 14px;
}
.drop p {
  color: #175d93;
}
.drop a {
  font-weight: 500;
}
.drop {
  width: 100%;
  justify-content: flex-end;
}

p,
a {
  color: #ffffff;
}
a {
  cursor: pointer;
  transition: all 0.2s linear;
}
a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
@media screen and (max-width:767px){
	#userContAdm{
		margin-top: 2%;
	}
  p{
    margin-bottom: 5px!important;
    margin-top: 2px!important;
  }
  .box{
		background-color: #4194d6;
		margin-bottom: 8%;
	}
  #boxContUnity{
    max-height: 300px;
    overflow: auto;
  }
}
</style>
