<template>
  <loader color="#e0a638" size="40" v-if="load" />
  <ValidationObserver v-else class="row" ref="form" tag="form">
    <ValidationProvider
      v-if="!stageEdit"
      tag="div"
      class="col-md-4 mb-3 form-group form-outline"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Nome do Terminal"
    >
      <label class="mb-1 label_default" :class="classes"
        >Selecione o Terminal</label
      >
      <div class="input-group">
        <select
          class="input_default_tenant form-control"
          :class="classes"
          v-model="terminal.terminalZigNet"
          @change="ChangeParamsZigNet()"
        >
          <option disabled />
          <option
            v-for="(item, index) in terminaisTef"
            :key="index"
            :value="item.terminalZigNet"
          >
            {{ item.terminalZigNet }}
          </option>
        </select>
        <div class="input-group-append">
          <div class="input-group-btn">
            <button class="btn btn-primary" @click.prevent="NewTerminalTef()">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
    </ValidationProvider>
    <ValidationProvider
      v-else
      tag="div"
      class="col-md-4 mb-3 form-group form-outline"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Nome do Terminal"
    >
      <label class="mb-1 label_default" :class="classes"
        >Informe o Terminal</label
      >
      <div class="input-group">
        <input
          class="input_default_tenant form-control"
          :class="classes"
          v-model="newTerminalName"
          @blur="VerifyEmpty($event)"
        />
        <div class="input-group-append">
          <div class="input-group-btn">
            <button
              class="btn btn-success"
              @click.prevent="SaveEditTerminalTef($event)"
            >
              <span
                class="spinner-border spinner-border-sm mb-1 mr-3"
                role="status"
                aria-hidden="true"
                v-if="stageSaving"
              ></span>
              <i class="fas fa-check" v-else></i>
            </button>
            <button
              class="btn btn-danger"
              :disabled="stageSaving"
              @click.prevent="CancelEditTerminalTef()"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </div>
    </ValidationProvider>
    <ValidationProvider
      tag="div"
      class="col-md-4 mb-3 form-group form-outline"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Selecione a Unidade"
    >
      <label class="mb-1 label_default" :class="classes"
        >Selecione a Unidade</label
      >
      <select
        class="input_default form-control"
        :class="classes"
        v-model="terminal.tenantId"
      >
        >
        <option disabled selected />
        <option v-for="(item, index) in tenants" :key="index" :value="item.id">
          {{ item.tenancyName }}
        </option>
      </select>
      <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
    </ValidationProvider>
    <ValidationProvider
      tag="div"
      class="col-md-4 mb-3"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Mensagem de Apresentação PinPad"
    >
      <label class="mb-1 label_default" :class="classes"
        >Mensagem Apresentação</label
      >
      <input
        type="text"
        class="input_default form-control"
        v-model="terminal.messagePinPad"
        :class="classes"
      />
      <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
    </ValidationProvider>
    <ValidationProvider
      tag="div"
      class="col-md-4 mb-3 form-group form-outline"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Selecione a Porta"
    >
      <label class="mb-1 label_default" :class="classes">Selecione Porta</label>
      <select
        class="input_default form-control"
        :class="classes"
        v-model="terminal.portPinPad"
      >
        <option disabled />
        <option
          v-for="(item, index) in pinPadPorts"
          :key="index"
          :value="item.id"
        >
          {{ item.port }}
        </option>
      </select>
      <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
    </ValidationProvider>
    <div class="col-md-4 mb-3">
      <label class="mb-1 label_default">Verificar Conexão PinPad</label>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="checkPinPad"
          v-model="terminal.checkPinPad"
        />
        <label class="form-check-label" for="checkPinPad">Sim</label>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <label class="mb-1 label_default"
        >Usar Pagamento QRCode *Desabilitado*</label
      >
      <div class="form-check">
        <input
          type="checkbox"
          id="checkQrCode"
          class="form-check-input"
          disabled
        />
        <label class="form-check-label" for="checkQrCode">Sim</label>
      </div>
    </div>
    <div
      v-if="stageEdit != '' && stageEdit"
      class="col-md-3 ml-auto"
      style="height: 40px;"
    ></div>
    <acoes :dados="terminal" :local="pinPadTerminal" v-else />
  </ValidationObserver>
</template>
<script>
import acoes from "./acoes/botao_rodape.vue";
import loader from "@/components/utils/_Load";

export default {
  components: {
    acoes,
    loader
  },
  props: ["terminal", "pinPadPorts"],
  data() {
    return {
      disabled: false,
      load: true,
      tenants: [],
      terminaisTef: [],
      pinPadTerminal: {},
      stageEdit: false,
      stageSaving: false,
      newTerminalName: ""
    };
  },
  mounted() {
    this.GetTenants();
    this.GetTerminaisTef();
  },
  methods: {
    GetTenants() {
      this.$store
        .dispatch("getTenants")
        .then(resolve => {
          this.tenants = resolve;
        })
        .catch(reject => {});
    },
    GetTerminaisTef() {
      this.$store
        .dispatch("GetAllVinculados", { vinculed: false })
        .then(resolve => {
          resolve.forEach(t => {
            this.terminaisTef.push(t);
          });
          this.load = false;
        })
        .catch(reject => {
          this.load = false;
        });
      console.log(this.terminaisTef);
    },
    VerifyEmpty(event) {
      if (this.newTerminalName === "") {
        event.target.focus();
      }
    },
    NewTerminalTef() {
      this.stageEdit = true;
    },
    SaveEditTerminalTef(event) {
      if (this.newTerminalName === "") {
        var element = event.target;
        if (element.tagName === "I") {
          //set tagButton
          element = element.parentNode;
        }
        var divP = element.parentNode.parentNode.parentNode.parentNode;
        var input = divP.querySelector("div input");
        input.focus();
      } else {
        this.stageSaving = true;
        this.$nextTick(() => {
          this.$store
            .dispatch("InsertNewTerminalCode", [
              { terminal: { chave: this.newTerminalName } }
            ])
            .then(resolve => {
              this.$nextTick(() => {
                this.stageSaving = false;
                this.stageEdit = false;
                let terminal = {
                  terminalZigNet: this.newTerminalName
                };
                this.terminaisTef.push(terminal);
                this.terminal.terminalZigNet = this.newTerminalName;
                this.newTerminalName = "";
                this.ChangeParamsZigNet();
              });
            })
            .catch(reject => {
              this.stageSaving = false;
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>${reject.msg}`
              });
            });
        });
      }
    },
    CancelEditTerminalTef() {
      this.stageEdit = false;
      this.newTerminalName = "";
    },
    ChangeParamsZigNet() {
      this.pinPadTerminal = this.terminaisTef.find(
        s => s.terminalZigNet == this.terminal.terminalZigNet
      );
    }
  }
};
</script>
<style scoped>
.failed span {
  font-size: 10px !important;
}
i.fas.fa-plus {
  font-size: 20px;
  margin-top: 5px;
}

i.fas.fa-check {
  font-size: 10px;
}
i.fas.fa-times {
  font-size: 10px;
}

.fas {
  font-size: 20px;
}
.btn-primary {
  height: 45px;
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 1.3px;
  width: 45px;
}

.btn-success {
  height: 45px;
  border: none;
  border-radius: 0;
  margin-right: 1.3px;
  width: 35px;
}

.btn-danger {
  height: 45px;
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 35px;
}

.input_default_tenant {
  height: 45px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
.modal_custom {
  max-width: 800px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}

label.failed {
  color: #dc3545 !important;
}

.input_default {
  height: 45px;
}

.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
</style>
