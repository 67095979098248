<template>
  <transition name="vertical">
    <component :is="cp" @enterCp="enterCp" :id="id" />
  </transition>
</template>

<script>
import categoria from "./categoriaSuporte/categoria";
import suporte from "./categoriaSuporte/suporte";
export default {
  components: {
    categoria,
    suporte
  },
  data() {
    return {
      cp: "categoria",
      id: ""
    };
  },
  mounted() {this.$store.dispatch("getSuporte");},
  methods: {
    enterCp(id, name) {
      this.id = id;
      this.cp = name;
    }
  },
  computed: {}
};
</script>

<style scoped>
.opacity {
  display: none;
}

.box_item >>> p {
  font-size: 14px;
}
.box {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border-color: #357eb8;
  border-style: solid;
  border-width: 0px 3px 0px 3px;
}
.box_item {
  height: auto;
  padding: 15px 0;
  cursor: pointer;
}
.box_item:nth-child(odd) {
  background-color: #357eb8;
}
.box_item:nth-child(even) {
  background-color: #175d93;
}

p,
a {
  color: #ffffff;
}
a {
  cursor: pointer;
  transition: all 0.2s linear;
}
a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
</style>