<template>
  <div
    class="tab-pane fade col-md-12"
    id="serviceValues"
    role="tabpanel"
    aria-labelledby="serviceValues-tab`"
  >
    <div class="row">
      <div class="col-md-7 d-flex">
        <ValidationProvider
          tag="div"
          class="col-md-6 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes, validate }"
          rules="required"
          name="Valor Padrão"
          :mode="custom"
        >
          <label class="mb-1 label_default" :class="classes"
            >Valor Padrão</label
          >
          <money
            v-model="valor"
            v-bind="money"
            class="input_default form-control"
            :class="classes"
            @blur.native="$emit('update:value', getValueBR(valor.toString()))"
          ></money>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="col-md-6 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes, validate }"
          rules="required"
          name="Secundário"
          :mode="custom"
        >
          <label class="mb-1 label_default" :class="classes"
            >Valor Secundário</label
          >
          <money
            v-model="secundario"
            v-bind="money"
            class="input_default form-control"
            :class="classes"
            @blur.native="
              $emit('update:secondValue', getValueBR(secundario.toString()))
            "
          ></money>
        </ValidationProvider>
      </div>
      <div class="col-md-4 mt-4">
        <div class="col-md-5">
          <button
            type="button"
            class="btn btn_default"
            @click="openModal('modal', {})"
          >
            + Adicionar
          </button>
        </div>
      </div>
    </div>
    <listServiceValues id="listServiceValues" :listValues.sync="listValues" @openModal="openModal" />
    <component
      :is="cpCreate"
      :serviceTenantId="serviceTenantData.id"
      :tenantId.sync="serviceTenantData.tenantId"
      :tenanancyName.sync="serviceTenantData.tenanancyName"
      :value.sync="serviceTenantData.value"
      :secondValue.sync="serviceTenantData.secondValue"
      :tenants.sync="serviceTenantData.tenants"
      :load="serviceTenantData.load"
      @closeModal="closeModal"
      @submitform="submitform"
      @submitformValues="submitformValues"
    />
    <component
      :is="cpDelete"
      :serviceName="serviceName"
      :tenanancyName="serviceTenantData.tenanancyName"
      @closeModal="closeModal"
      @removeTenant="removeTenant"
    />
  </div>
</template>

<script>
import { Money } from "v-money";
import { HalfCircleSpinner } from "epic-spinners";
import { TheMask } from "vue-the-mask";
import { mask } from "vue-the-mask";
import modal from "./components/valuesAndComissions/modal/createEditValues.vue";
import modalDelete from "./components/valuesAndComissions/modal/delete.vue";
export default {
  props: ["serviceName", "listValues", "value", "secondValue", "objId"],
  directives: { mask },
  components: {
    HalfCircleSpinner,
    TheMask,
    Money,
    listServiceValues: () =>
      import("./components/valuesAndComissions/tableValueTenants.vue"),
    modal,
    modalDelete
  },
  data() {
    return {
      serviceTenantData: {
        id: "",
        tenantId: "",
        tenanancyName: "",
        value: "",
        secondValue: "",
        tenants: [],
        load: false
      },
      secundario: this.secondValue,
      valor: this.value,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      cpDelete: "",
      cpCreate: ""
    };
  },
  methods: {
    custom() {
      return { on: ["blur"], debounce: 1000 };
    },
    openModal(
      idModal,
      { id, value, secondValue, tenantId, tenanancyName, tenants }
    ) {
      this.serviceTenantData = {
        id,
        value,
        secondValue,
        tenantId,
        tenanancyName,
        tenants
      };
      if (idModal === "modal") {
        this.cpCreate = idModal;
        const news = [undefined, ""];
        const query = news.some(
          s =>
            s === this.serviceTenantData.tenanancyName ||
            s === this.serviceTenantData.tenantId
        );
        if (query) {
          this.getTenantsActive();
          this.serviceTenantData.tenantId = "";
          this.serviceTenantData.tenanancyName = "";
          this.serviceTenantData.id = "";
        }
      } else if (idModal === "modalDelete") {
        this.cpDelete = idModal;
      } else {
        this.$toast(
          `Não foi possivel identificar a operação`,
          this.$toast.error
        );
        return;
      }
    },
    closeModal(idModal) {
      $(`#${idModal}`).modal("hide");
      this.cpDelete = "";
      this.cpCreate = "";
    },
    getTenantsActive() {
      this.$store
        .dispatch("getAllTenants")
        .then(resolve => {
          this.serviceTenantData.load = false;
          const result = resolve.activeTenants;

          this.serviceTenantData.tenants = result.filter(
            r => !this.listValues.some(lv => lv.tenantId == r.id)
          );
        })
        .catch(reject => {
          console.log("error", reject);
        });
    },
    submitform() {
      this.$emit("updateListValues", this.serviceTenantData);
    },
    submitformValues(tenants, value, secondValue) {
      for (let i = 0; i < tenants.length; i++) {
        this.serviceTenantData.tenantId = tenants[i].id;
        this.serviceTenantData.tenanancyName = tenants[i].name;
        this.serviceTenantData.value = value;
        this.serviceTenantData.secondValue = secondValue;
        this.$emit("updateListArrayValues", this.serviceTenantData);
      }

      this.toast(
        `${this.objId == "" ? "Adicionado" : "Atualizado"}`,
        this.$toast.info
      );
    },
    removeTenant(tenantName) {
      this.$emit("removeListValues", tenantName);
    }
  },
  watch: {
    cpCreate: function() {
      if (this.cpCreate != "") {
        setTimeout(() => {
          $(`#${this.cpCreate}`).modal("show");
        }, 100);
      }
    },
    cpDelete: function() {
      if (this.cpDelete != "") {
        setTimeout(() => {
          $(`#${this.cpDelete}`).modal("show");
        }, 100);
      }
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.label_default {
  overflow: hidden !important;
  text-overflow: ellipsis;
}
@media (max-width: 402px) {
  .d-flex {
    display: inline !important;
  }
}
</style>
