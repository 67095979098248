<template>
	<div class="row">
		<hr class="w-100" />
		<div class="col-md-12 px-0">
			<p class="titulo_dash">Mostrar popup</p>
		</div>
		<div class="col-md-2 px-0">
			<div class="d-flex align-items-center">
				<div class="checkbox">
					<input
						type="checkbox"
						name="type2"
						:value="true"
						@change="$emit('update:hasPopup',true)"
						:checked="hasPopup"
						id="type2"
					/>
					<label for="type2"></label>
				</div>
				<p class="mb-0 ml-2">Sim</p>
			</div>
		</div>
		<div class="col-md-2 px-0">
			<div class="d-flex align-items-center">
				<div class="checkbox">
					<input
						type="checkbox"
						id="type1"
						name="type1"
						:value="false"
						:checked="!hasPopup"
						@change="$emit('update:hasPopup', false)"
					/>
					<label for="type1"></label>
				</div>
				<p class="mb-0 ml-2">Não</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["hasPopup"],
		mounted() {
		},
	};
</script>

<style scoped>
.titulo_dash {
	color: #2474b2;
	font-weight: 500;
}
.input_default {
	height: auto;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.input-group-text {
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	font-size: 14px;
	background-color: #868686;
	color: #ffff;
	font-weight: 500;
}
</style>
