<template>
  <div>
    <div
      class="modal fade"
      id="modalPessoa"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_custom"
        role="document"
      >
        <div class="modal-content container">
          <div class="modal-body px-4 py-4">
            <p class="titulo_dash mb-4">
              {{ editUser == "" ? "Adicionar Usuário" : "Editar Usuário" }}
            </p>
            <ValidationObserver
              ref="form"
              class="row"
              tag="form"
              @submit.prevent="editUser != '' ? userEdit() : sendPessoa()"
            >
              <div class="col-md-4 mb-4 text-center">
                <div class="box_image w-100 position-relative">
                  <img
                    :src="
                      newPessoa.photo.includes('data')
                        ? newPessoa.photo
                        : getImg(newPessoa.photo)
                    "
                    alt
                    @error="errorImage2"
                    class="w-100"
                  />
                  <input
                    class="btnAlteraFoto"
                    type="file"
                    @change="onChoseFile"
                    id="file"
                    style="color:transparent!important;"
                    accept="image/x-png, image/gif, image/jpeg"
                    placeholder="Alterar"
                  />
                </div>
              </div>

              <div class="col-md-8 mb-4">
                <ValidationProvider
                  tag="div"
                  class="row my-3"
                  v-slot="{ errors, ariaMsg, classes }"
                  rules="required"
                  name="Perfil"
                >
                  <div class="col-md-12">
                    <label class="mb-3 label_default" :class="classes"
                      >Perfil</label
                    >
                  </div>
                  <div
                    class="col-md-4"
                    v-if="$store.getters.getUser.profile == 1"
                  >
                    <label class="mb-0 mr-2 input_radio" :class="classes"
                      >Master</label
                    >
                    <input
                      type="radio"
                      v-model="newPessoa.profile"
                      value="1"
                      :class="classes"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="mb-0 mr-2 input_radio" :class="classes"
                      >Franqueado</label
                    >
                    <input
                      type="radio"
                      v-model="newPessoa.profile"
                      value="2"
                      :class="classes"
                    />
                  </div>
                  <div
                    class="col-md-4"
                    v-if="$store.getters.getUser.profile == 2"
                  >
                    <label class="mb-0 mr-2 input_radio" :class="classes"
                      >Vendedor</label
                    >
                    <input
                      type="radio"
                      v-model="newPessoa.profile"
                      :class="classes"
                      value="3"
                    />
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="row my-3"
                  v-slot="{ errors, ariaMsg, classes }"
                  rules="required"
                  name="Sexo"
                >
                  <div class="col-md-12">
                    <label class="mb-3 label_default" :class="classes"
                      >Sexo</label
                    >
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex">
                      <label class="mb-0 mr-2 input_radio" :class="classes"
                        >Masculino</label
                      >
                      <input
                        type="radio"
                        value="1"
                        :class="classes"
                        v-model="newPessoa.genre"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex">
                      <label class="mb-0 mr-2 input_radio" :class="classes"
                        >Feminino</label
                      >
                      <input
                        type="radio"
                        value="2"
                        :class="classes"
                        v-model="newPessoa.genre"
                      />
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <ValidationProvider
                tag="div"
                class="col-md-8 mb-3"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="Nome completo"
              >
                <label class="mb-1 label_default" :class="classes"
                  >Nome completo</label
                >
                <input
                  type="text"
                  class="input_default form-control"
                  :class="classes"
                  @keypress="validarNome($event)"
                  v-model="newPessoa.name"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-md-4 mb-3"
                v-slot="{ errors, ariaMsg, classes, validate }"
                rules="required|validadeCpf"
                name="CPF"
              >
                <label class="mb-1 label_default" :class="classes">CPF</label>
                <the-mask
                  type="text"
                  class="input_default form-control"
                  v-model="newPessoa.cpf"
                  mask="###.###.###-##"
                  @blur.native="validate($event)"
                  placeholder="000.000.000-00"
                  :masked="false"
                  :class="classes"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-md-4 mb-3"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="Nome social"
              >
                <label class="mb-1 label_default" :class="classes"
                  >Nome social</label
                >
                <input
                  type="text"
                  class="input_default form-control"
                  v-model="newPessoa.displayName"
                  :class="classes"
                  @keypress="validarNome($event)"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-md-4 mb-3"
                v-slot="{ errors, ariaMsg, classes, validate }"
                rules="required|min:10"
                name="Telefone"
              >
                <label class="mb-1 label_default" :class="classes"
                  >Telefone</label
                >
                <the-mask
                  type="text"
                  class="input_default form-control"
                  v-model="newPessoa.cellphone"
                  :class="classes"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  @blur.native="validate($event)"
                  :masked="true"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-md-4 mb-3"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required|email"
                name="Email"
              >
                <label class="mb-1 label_default" :class="classes">Email</label>
                <input
                  type="text"
                  class="input_default form-control"
                  v-model="newPessoa.email"
                  :class="classes"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-lg-5 mb-3 margin_botton_label"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required|min:5|confirmed:confirm"
                name="Senha"
                v-if="editUser == ''"
              >
                <label class="mb-1 label_default" :class="classes">Senha</label>
                <input
                  id="password"
                  type="password"
                  class="input_default form-control"
                  v-model="newPessoa.password"
                  :class="classes"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-lg-5 mb-3 margin_botton_label"
                v-slot="{ errors, ariaMsg, classes, validate }"
                rules="required"
                vid="confirm"
                @blur.native="validate($event)"
                name="Confirmação senha"
                v-if="editUser == ''"
              >
                <label class="mb-1 label_default">Confirme a senha</label>
                <input
                  id="confirmPassword"
                  type="password"
                  class="input_default form-control"
                  v-model="confirm"
                />
              </ValidationProvider>
              <div class="col-md-1 mb-3 div-eye margin_botton_label">
                <i
                  id="eye"
                  :class="showPswd == true ? 'fas fa-eye eye_color' : 'fas fa-eye-slash'"
                  @click.prevent="showPassword()"
                ></i>
                <i
                  id="lock"
                  :class="generatePswd == true ? 'fas fa-lock lock_color' : 'fas fa-lock'"
                  @click.prevent="generatePassword()"
                ></i>
              </div>
              <div class="col-lg-12 mb-3">
                <label class="mb-1 label_password">A senha deve conter uma combinação de: letras maiúsculas, minúsculas, números e símbolos (!#@$%&).</label>
              </div>
              <div class="col-md-3" v-if="editUser != ''">
                <button
                  class="btn_default d-flex justify-content-center align-items-center btn_default_excluir"
                  :disabled="disabledDelete"
                  :class="disabled ? 'btn_default_disabled' : ''"
                  @click.prevent="excludePessoa()"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="disabledDelete"
                  ></span>
                  <i class="fas fa-trash-alt mr-1" v-show="!disabledDelete"></i>
                  {{ !disabledDelete ? `Excluir` : "" }}
                </button>
              </div>
              <div class="col-md-3 ml-auto">
                <button
                  class="btn_default d-flex justify-content-center align-items-center"
                  :disabled="disabled || disabledDelete"
                  :class="disabledDelete ? 'btn_default_disabled' : ''"
                  type="submit"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="disabled"
                  ></span>
                  <i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
                  {{
                    !disabled ? `${editUser != "" ? "Editar" : "Criar"}` : ""
                  }}
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { mask } from "vue-the-mask";
import { extend } from "vee-validate";
export default {
  props: ["editUser"],
  directives: { mask },
  components: { TheMask },
  data() {
    return {
      newPessoa: {
        displayName: "",
        name: "",
        email: "",
        photo: "",
        password: "",
        cellphone: "",
        cpf: "",
        profile: "",
        genre: ""
      },
      confirm: "",
      disabled: false,
      disabledDelete: false,
      showPswd: false,
      generatePswd: false
    };
  },
  mounted() {
    if (this.editUser != "") {
      this.newPessoa = this.editUser;
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    }
    var self = this;
    $("#modalPessoa").on("hide.bs.modal", function(e) {
      self.$parent.editUser = [];
      self.$parent.cp = "";
    });
    this.registerValidations();
  },
  methods: {
    registerValidations() {
      extend("validadeCpf", {
        validate: this.validarCPF,

        message: (field, params, data) => {
          return "CPF inválido";
        }
      });
    },
    sendPessoa() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.$store.dispatch("newPessoa", this.newPessoa).then(resolve => {
            this.disabled = false;
            if (resolve.success) {
              this.$notify({
                group: "erros",
                type: "sucess",
                text: `<i class="icon ion-close-circled"></i>${resolve.msg}`
              });
              this.$emit("getPessoas");

              $("#modalPessoa").modal("hide");
            } else {
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>${resolve.msg}`
              });
            }
          });
        }
      });
    },
    userEdit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.$store
            .dispatch("editCurrentUser", this.newPessoa)
            .then(resolve => {
              this.disabled = false;
              if (resolve.success) {
                this.$notify({
                  group: "erros",
                  type: "sucess",
                  text: `<i class="icon ion-close-circled"></i>${resolve.msg}`
                });

                this.$emit("getPessoas");

                $("#modalPessoa").modal("hide");
              } else {
                this.$notify({
                  group: "erros",
                  type: "error",
                  text: `<i class="icon ion-close-circled"></i>${resolve.msg}`
                });
              }
            });
        }
      });
    },
    excludePessoa() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabledDelete = true;

          this.$store
            .dispatch("deleteUser", this.newPessoa.id)
            .then(resolve => {
              this.disabledDelete = false;

              this.$emit("getPessoas");
              setTimeout(function() {
                $("#modalPessoa").modal("hide");
              }, 2500);
            })
            .catch(reject => {
              this.disabledDelete = false;
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>${reject.msg}`
              });
            });
        }
      });
    },
    onChoseFile: function(event) {
      // Reference to the DOM input element
      var input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        if (Math.round(input.files[0].size / 1024) >= 1000) {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i> Ooops, Arquivo maior que 1MB! Por favor ultilize um compressor ou selecione outro arquivo! \r Tamanho: ${Math.round(
              input.files[0].size / 1024
            )}KB`
          });

          $("#file").val("");
          return false;
        }
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData//
          this.newPessoa.photo = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    validarCPF(value) {
      let cpf = value;
      cpf = cpf.replace(/[^\d]+/g, "");
      if (cpf == "") return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      )
        return false;
      // Valida 1o digito
      let rev;
      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;

      return true;
    },
    validarNome(event) {
      if (
        event.charCode === 0 ||
        /^[A-Za-zÇç'áàÁÀéèÉÈíìÍÌóòÓÒùúãÃõÕâÂêÊôÔ ]/.test(
          String.fromCharCode(event.charCode)
        )
      ) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    showPassword() {
      if (this.showPswd == true) {
        this.showPswd = false;
        $("#password").attr("type", "password");
        $("#confirmPassword").attr("type", "password");
      } else {
        this.showPswd = true;
        $("#password").attr("type", "text");
        $("#confirmPassword").attr("type", "text");
      }
    },
    generatePassword() {
      if (this.generatePswd == true) {
        this.generatePswd = false;
        $("#password").attr("value", "");
        $("#confirmPassword").attr("value", "");
        $("#password")[0].value = "";
        $("#confirmPassword")[0].value = "";
      } else {
        this.generatePswd = true;
        let stringAleatoria = "";
        let caracteresMaiusculos = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let caracteresMainusculos = 'abcdefghijklmnopqrstuvwxyz';
        let numeros = '0123456789';
        let caracteresEspeciais = "!#@$%&"
        for (let i = 0; i < 2; i++) {
          stringAleatoria += caracteresMaiusculos.charAt(Math.floor(Math.random() * caracteresMaiusculos.length))
            + caracteresMainusculos.charAt(Math.floor(Math.random() * caracteresMainusculos.length))
            + numeros.charAt(Math.floor(Math.random() * numeros.length))
            + caracteresEspeciais.charAt(Math.floor(Math.random() * caracteresEspeciais.length));
        }
        let stringArray = stringAleatoria.split();
        for (let i = stringArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [stringArray[i], stringArray[j]] = [stringArray[j], stringArray[i]];
        }
        stringAleatoria = stringArray.join();
        this.newPessoa.password = stringAleatoria;
        this.confirm = stringAleatoria;
      }
    }
  },
  watch: {
    editUser: function() {
      if (this.editUser != "") {
        this.newPessoa = this.editUser;
      }
    }
  },
  computed: {}
};
</script>

<style scoped>
.box_image.failed {
  border: 1px solid #dc3545 !important;
}
.box_image img {
  height: 137px;
  object-fit: cover;
}
.failed span {
  font-size: 10px !important;
}
.box_image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.box_image:hover::after {
  contain: "";
  opacity: 0.1;
}

.btnAlteraFoto::-webkit-file-upload-button {
  visibility: hidden;
}
.btnAlteraFoto::after {
  content: "Alterar foto";
  color: #fff;
  outline: none;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 11px;
  opacity: 0;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
.box_image:hover .btnAlteraFoto::after {
  opacity: 1;
}
.btnAlteraFoto {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 22;
}
.input_radio {
  font-size: 12px;
  color: #175d93;
}
.box_image {
  border-radius: 20px;
  overflow: hidden;
}
input[type="file"] {
  background: transparent;
  border: none !important;
  position: absolute;
  width: 100%;
  color: transparent;
}
.fas {
  font-size: 20px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
.modal_custom {
  max-width: 640px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}

label.failed {
  color: #dc3545 !important;
}

.input_default {
  height: 45px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.div-eye {
    display: flex;
    align-items: center;
}
#eye {
  margin-top: 20px;
  margin-right: 10px;
  color: #9C9C9C;
}
#lock {
  margin-top: 20px;
  color: #9C9C9C;
}
.label_password{
  color: #9C9C9C;
  font-size: 10px;
}
.margin_botton_label{
  margin-bottom: 1px !important;
}
.eye_color, .lock_color{
  color: #e0a638 !important;
}
@media screen and (max-width:767px){
  .mb-3, .my-3{
    margin-bottom: 0.2rem!important;
  }
  .btn_default_excluir {
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .modal-content{
    margin-bottom: 2rem;
  }
  .modal-body px-4 py-4{
    padding-bottom: 1rem!important;
    padding-top: 1rem!important;
  }
}
</style>
