<template>
  <div
    id="modal"
    class="modal fade bd-example-modal-lg"
    tabindex="-1"
    data-backdrop="static"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal_custom"
      role="document"
    >
      <div class="modal-content container">
        <div class="modal-body px-4 py-4">
          <button type="button" class="close" @click="CloseModal()">
            <span aria-hidden="true">×</span>
          </button>
          <p class="titulo_dash mb-4">
            {{ this.cp != "Edit" ? "Adicionar Terminal" : "Editar Terminal" }}
          </p>
          <component :is="cp" :terminal="terminal" :pinPadPorts="pinPadPorts" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Create from "./modal/cpCreate.vue";
import Edit from "./modal/cpEdit.vue";

export default {
  components: {
    Edit,
    Create
  },
  props: ["cp", "terminal"],
  data() {
    return {
      edit: false,
      pinPadPorts: [
        {
          id: "01",
          port: "COM1"
        },
        {
          id: "02",
          port: "COM2"
        },
        {
          id: "03",
          port: "COM3"
        },
        {
          id: "04",
          port: "COM4"
        },
        {
          id: "05",
          port: "COM5"
        },
        {
          id: "06",
          port: "COM6"
        },
        {
          id: "07",
          port: "COM7"
        },
        {
          id: "08",
          port: "COM8"
        },
        {
          id: "09",
          port: "COM9"
        },
        {
          id: "10",
          port: "COM10"
        },
        {
          id: "AUTO_USB",
          port: "AUTO USB"
        }
      ]
    };
  },
  methods: {
    CloseModal() {
      this.$parent.CloseModal();
    }
  }
};
</script>

<style scoped>
.failed span {
  font-size: 10px !important;
}
.fas {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
.modal_custom {
  max-width: 740px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}

label.failed {
  color: #dc3545 !important;
}

.input_default {
  height: 45px;
}

.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
</style>
